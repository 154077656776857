import React, { useState } from 'react';
import SuperstarMenu from './SuperstarMenu';
import YouTube from 'react-youtube';
import { SUPERSTARS } from '../config/Superstars';
import './SuperstarSelection.css';

const SuperstarSelection = ({ onSelectSuperstar }) => {
  const [selectedSuperstar, setSelectedSuperstar] = useState('');
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const handleSelectSuperstar = (superstarKey) => {
    setSelectedSuperstar(superstarKey);
    onSelectSuperstar(superstarKey);
    setIsMenuVisible(false);
  };

  const handlePlayFullScreen = () => {
    const player = document.getElementById('superstar-youtube-player');
    if (player) {
      player.requestFullscreen();
    }
  };

  const onReady = (event) => {
    event.target.playVideo();
  };

  const onStateChange = (event) => {
    if (event.data === YouTube.PlayerState.CUED) {
      event.target.playVideo();
    }
  };

  return (
    <div>
      <h2>Select Superstar</h2>

      <button onClick={() => setIsMenuVisible(!isMenuVisible)}>
        {isMenuVisible ? 'Hide Menu' : 'Show Menu'}
      </button>

      <SuperstarMenu
        onSelectSuperstar={handleSelectSuperstar}
        isVisible={isMenuVisible}
      />

      {selectedSuperstar && (
        <div>
          {/* Display Superstar details or related content here */}
          <p>Name: {SUPERSTARS[selectedSuperstar].name}</p>

          {/* Add more details as needed */}

          {/* Display YouTube video */}
          <div>
            <YouTube
              videoId={SUPERSTARS[selectedSuperstar].youtubeVideoId}
              opts={{ width: '80%', height: '80%' }}
              id="superstar-youtube-player"
              onReady={onReady}
              onStateChange={onStateChange} // Handle state changes
            />
            <button onClick={handlePlayFullScreen}>Play Fullscreen</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SuperstarSelection;
