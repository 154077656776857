import React from 'react';

import Dashboard from '../components/Dashboard';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Home = () => {
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div>
        <Dashboard />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
