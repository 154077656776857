export const SUPERSTARS = {
  AJStyles: {
    name: 'AJ Styles',
    imgUrl: '/images/Superstars/AJStyles.png',
    youtubeVideoId: 'ltn2G8nP0bQ',
  },
  BigE: {
    name: 'Big E',
    imgUrl: '/images/Superstars/BigE.png',
    youtubeVideoId: 'BvphxNq51XU',
  },
  BrayWyatt: {
    name: 'Bray Wyatt',
    imgUrl: '/images/Superstars/BrayWyatt.png',
    youtubeVideoId: 'eTR28PRBbDo',
  },
  BrockLesnar: {
    name: 'Brock Lesnar',
    imgUrl: '/images/Superstars/BrockLesnar.png',
    youtubeVideoId: 'sgNEOGdB1rE',
  },
  CMPunk: {
    name: 'CM Punk',
    imgUrl: '/images/Superstars/CMPunk.png',
    youtubeVideoId: 'Zr4eFWuyHj4',
  },
  DanielBryan: {
    name: 'Daniel Bryan',
    imgUrl: '/images/Superstars/DanielBryan.png',
    youtubeVideoId: 'GuJoZVarveg',
  },
  DeanAmbrose: {
    name: 'Dean Ambrose',
    imgUrl: '/images/Superstars/DeanAmbrose.png',
    youtubeVideoId: 'NgusD4Ck098',
  },
  JohnCena: {
    name: 'John Cena',
    imgUrl: '/images/Superstars/JohnCena.png',
    youtubeVideoId: 'gIWrjp3zG-M',
  },
  KofiKingston: {
    name: 'Kofi Kingston',
    imgUrl: '/images/Superstars/KofiKingston.png',
    youtubeVideoId: 'BvphxNq51XU',
  },
  RandyOrton: {
    name: 'Randy Orton',
    imgUrl: '/images/Superstars/RandyOrton.png',
    youtubeVideoId: 'DQXoYNt8eTE',
  },
  ReyMysterio: {
    name: 'Rey Mysterio',
    imgUrl: '/images/Superstars/ReyMysterio.png',
    youtubeVideoId: '55JfPBGqmJM',
  },
  RomanReigns: {
    name: 'Roman Reigns',
    imgUrl: '/images/Superstars/RomanReigns.png',
    youtubeVideoId: '1Qrv-mPuIyc',
  },
  SethRollins: {
    name: 'Seth Rollins',
    imgUrl: '/images/Superstars/SethRollins.png',
    youtubeVideoId: 'TbXKg4-u9hc',
  },
  TheMiz: {
    name: 'The Miz',
    imgUrl: '/images/Superstars/TheMiz.png',
    youtubeVideoId: 'MX7ERbtXkm0',
  },
  TheRock: {
    name: 'The Rock',
    imgUrl: '/images/Superstars/TheRock.png',
    youtubeVideoId: 'D5MidoYEVMg',
  },
  TripleH: {
    name: 'Triple H',
    imgUrl: '/images/Superstars/TripleH.png',
    youtubeVideoId: 'wXvsETUA9WU',
  },
  Undertaker: {
    name: 'Undertaker',
    imgUrl: '/images/Superstars/Undertaker.png',
    youtubeVideoId: 'jya2eBCDcW0',
  },
  XavierWoods: {
    name: 'Xavier Woods',
    imgUrl: '/images/Superstars/XavierWoods.png',
    youtubeVideoId: 'BvphxNq51XU',
  },
};
