import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import { Route, Routes } from 'react-router-dom';
const App = () => (
  <Routes>
    <Route path="/" element={<Home />}></Route>

    <Route path="about" element={<About />}></Route>

    {/* End Protected Routes */}
  </Routes>
);

export default App;
