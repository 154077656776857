// File: AboutPage.js

import React from 'react';
import { Box, Typography, Link, Divider } from '@mui/material';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const AboutPage = () => {
  return (
    <div>
      <Navbar />

      <Box sx={{ maxWidth: 800, margin: 'auto', padding: 3 }}>
        <Typography variant="h1" sx={{ mb: 3, fontFamily: 'Microgramma-Bold' }}>
          About Titantron<span style={{ color: 'red' }}>Hub</span>
        </Typography>

        <Box sx={{ mb: 3 }}>
          <Typography
            variant="body1"
            sx={{ fontFamily: 'MicrogrammaEF-ExtendedMedium' }}
          >
            Welcome to TitantronHub, your ultimate destination for Wrestling
            Stages. Whether you are a content creator or just want to have fun
            with your custom stages TitantronHub has you covered.
          </Typography>
        </Box>

        <Box sx={{ mt: 3 }}>
          <Typography variant="h2">Mission</Typography>
          <Typography
            variant="body1"
            sx={{ fontFamily: 'MicrogrammaEF-ExtendedMedium' }}
          >
            At TitantronHub, I wanted to provide a unique and engaging platform
            for wrestling enthusiasts to have fun with custom Titantron stages
            including event logos, Superstar entrance music and videos, and
            event promo videos.
          </Typography>
        </Box>

        <Box sx={{ mt: 3 }}>
          <Typography variant="h2">Feedback</Typography>
          <Typography
            variant="body1"
            sx={{ fontFamily: 'MicrogrammaEF-ExtendedMedium' }}
          >
            Any feedback is welcomed and highly encouraged to bring even better
            experiences.
          </Typography>
        </Box>

        <Box sx={{ mt: 3 }}>
          <Typography variant="h2">Contact</Typography>
          <Typography
            variant="body1"
            sx={{ fontFamily: 'MicrogrammaEF-ExtendedMedium' }}
          >
            Have questions, suggestions, or just want to say hello? Feel free to
            reach out{' '}
            <Link href="mailto:andyvalencia.cs@gmail.com">
              contact@titantronhub.com
            </Link>
            .
          </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />
      </Box>

      <Footer />
    </div>
  );
};

export default AboutPage;
