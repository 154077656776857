// EventSelection.js
import React, { useState } from 'react';
import { EVENTS } from '../config/Events';

const EventSelection = ({ onSelectEvent }) => {
  const eventKeys = Object.keys(EVENTS);
  const [selectedEvent, setSelectedEvent] = useState('');

  const handleChange = (event) => {
    setSelectedEvent(event.target.value);
    onSelectEvent(event.target.value);
  };

  const handleLogoClick = () => {
    const logoElement = document.getElementById('fullscreen-logo');

    if (logoElement) {
      if (logoElement.requestFullscreen) {
        logoElement.requestFullscreen();
      } else if (logoElement.mozRequestFullScreen) {
        logoElement.mozRequestFullScreen();
      } else if (logoElement.webkitRequestFullscreen) {
        logoElement.webkitRequestFullscreen();
      } else if (logoElement.msRequestFullscreen) {
        logoElement.msRequestFullscreen();
      }
    }
  };

  return (
    <div>
      <div style={{ marginBottom: '20px', textAlign: 'center' }}>
        <label
          style={{ marginRight: '10px', fontSize: '16px', fontWeight: 'bold' }}
        >
          Select Event:
        </label>
        <select
          onChange={handleChange}
          value={selectedEvent}
          style={{
            padding: '8px',
            fontSize: '14px',
            border: '1px solid red',
            backgroundColor: 'black',
            color: 'white',
          }}
        >
          <option value=""></option>
          {eventKeys.map((eventKey, index) => (
            <option key={index} value={eventKey}>
              {EVENTS[eventKey].name}
            </option>
          ))}
        </select>
      </div>

      <div>
        {' '}
        {selectedEvent && (
          <img
            id="fullscreen-logo"
            src={EVENTS[selectedEvent].imgUrl}
            alt={`${EVENTS[selectedEvent].name} Logo`}
            style={{
              cursor: 'pointer',
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
            onClick={handleLogoClick}
          />
        )}
      </div>
    </div>
  );
};

export default EventSelection;
