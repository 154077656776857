// config/Events.js
export const EVENTS = {
  Raw: {
    name: 'Raw',
    imgUrl: '/images/Raw.gif',
    videos: ['QI0QcathBQs'],
  },
  SmackDown: {
    name: 'SmackDown',
    imgUrl: '/images/Smack.gif',
    videos: ['qV7bsrVNcEU'],
  },
  RoyalRumble: {
    name: 'RoyalRumble',
    imgUrl: '/images/Royal.gif',
    videos: ['AZOVo2tiBvw', 'PU_EUSJOK3U'],
  },
  Backlash: {
    name: 'Backlash',
    imgUrl: '/images/backlash.gif',
    videos: ['2IicVbQUJB0'],
  },
  Chamber: {
    name: 'Elimination Chamber',
    imgUrl: '/images/Chamber.gif',
    videos: ['OcbrtIMvi80'],
  },
  Crown: {
    name: 'Crown Jewel',
    imgUrl: '/images/Crown.gif',
    videos: ['SRW7S1cHxwo'],
  },
  Fastlane: {
    name: 'Fastlane',
    imgUrl: '/images/Fastlane.gif',
    videos: ['rtk8mwl1Vqo'],
  },
  Money: {
    name: 'Money in the Bank',
    imgUrl: '/images/Money.gif',
    videos: ['dcf5g20C92M'],
  },
  Night: {
    name: 'Night of Champions',
    imgUrl: '/images/Night.gif',
    videos: ['A2DVrz9DVQ0'],
  },
  NXT: {
    name: 'NXT',
    imgUrl: '/images/NXT.gif',
    videos: ['UkmCjN_fkk8'],
  },
  Payback: {
    name: 'Payback',
    imgUrl: '/images/Payback.gif',
    videos: ['z-soHnDp1mo'],
  },
  Summerslam: {
    name: 'Summerslam',
    imgUrl: '/images/Summerslam.gif',
    videos: ['7-ek1MlAlWM'],
  },
  Wrestlemania: {
    name: 'Wrestlemania',
    imgUrl: '/images/WM.gif',
    videos: ['If0oeQiWtSQ'],
  },
  SurvivorSeries: {
    name: 'SurvivorSeries',
    imgUrl: '/images/SurvivorSeries.gif',
    videos: ['DaH-DUOkRFk'],
  },
};
