import React from 'react';
import { SUPERSTARS } from '../config/Superstars';

const SuperstarMenu = ({ onSelectSuperstar, isVisible }) => {
  const superstarKeys = Object.keys(SUPERSTARS);

  if (!isVisible) {
    return null; // Return null if the menu is not visible
  }

  return (
    <div className="superstar-menu">
      {superstarKeys.map((superstarKey, index) => (
        <div
          key={index}
          className="superstar-item"
          onClick={() => onSelectSuperstar(superstarKey)}
        >
          <div className="superstar-background"></div>
          <img
            src={SUPERSTARS[superstarKey].imgUrl}
            alt={SUPERSTARS[superstarKey].name}
            className="superstar-image"
          />
          <p>{SUPERSTARS[superstarKey].name}</p>
        </div>
      ))}
    </div>
  );
};

export default SuperstarMenu;
