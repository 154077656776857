// Dashboard.js
import React, { useState } from 'react';
import EventSelection from './EventSelection';
import VideoDisplay from './VideoDisplay';
import SuperstarSelection from './SuperstarSelection';
import { EVENTS } from '../config/Events';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

const Dashboard = () => {
  const [selectedEvent, setSelectedEvent] = useState('');
  const [selectedSuperstar, setSelectedSuperstar] = useState('');

  const [videoData, setVideoData] = useState({}); // Store video data for each event

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);

    // Fetch video data based on the selected event (implement this function)
    fetchVideoData(event);
  };

  const handleSelectSuperstar = (superstar) => {
    setSelectedSuperstar(superstar);

    // Handle any additional logic related to the selected superstar
  };

  const fetchVideoData = (event) => {
    // Implement video data fetching logic here
    const eventVideos = EVENTS[event]?.videos || [];

    if (eventVideos.length > 0) {
      setVideoData({
        [event]: eventVideos,
      });
    } else {
      // If no videos for the selected event, clear videoData
      setVideoData({});
    }
  };
  return (
    <Grid
      container
      spacing={6}
      justifyContent="center"
      style={{ paddingBottom: '20%' }}
    >
      <Grid item xs={12}>
        <Box p={2}></Box>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper elevation={6}>
          <Box p={2}>
            <EventSelection onSelectEvent={handleSelectEvent} />
          </Box>
        </Paper>
      </Grid>

      {selectedEvent &&
        Object.keys(videoData).length > 0 &&
        Object.values(videoData)[0].length > 0 && (
          <Grid item xs={12} md={6}>
            <Paper elevation={6}>
              <Box p={2}>
                <VideoDisplay videoIds={videoData[selectedEvent]} />
              </Box>
            </Paper>
          </Grid>
        )}

      <Grid item xs={12}>
        <Paper elevation={6}>
          <Box p={2}>
            <SuperstarSelection
              onSelectSuperstar={handleSelectSuperstar}
              selectedSuperstar={selectedSuperstar}
            />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
