import React from 'react';
import Logo from '../assets/logos/whiteLogo.png';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer">
      <img
        src={Logo}
        alt="Company Logo"
        style={{ width: '100px', height: '100px', marginRight: '10px' }}
      />

      <br />
      <span>® {currentYear} Andy Valencia. All rights reserved.</span>
    </div>
  );
};

export default Footer;
