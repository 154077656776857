import React from "react";
import { Box } from "@mui/material";

const VideoDisplay = ({ videoIds }) => {
  const handlePlayFullScreen = (event) => {
    const iframe = event.currentTarget.previousSibling;

    if (iframe.requestFullscreen) {
      iframe.requestFullscreen();
    } else if (iframe.mozRequestFullScreen) {
      iframe.mozRequestFullScreen();
    } else if (iframe.webkitRequestFullscreen) {
      iframe.webkitRequestFullscreen();
    } else if (iframe.msRequestFullscreen) {
      iframe.msRequestFullscreen();
    }
  };

  return (
    <div>
      <h2>Event Intros/Promos</h2>

      {videoIds.map((videoId, index) => (
        <Box key={index}>
          <iframe
            width="350"
            height="300"
            src={`https://www.youtube.com/embed/${videoId}`}
            title={`YouTube Video ${index + 1}`}
            allowFullScreen
          ></iframe>
          <button onClick={handlePlayFullScreen}>Play Fullscreen</button>
        </Box>
      ))}
    </div>
  );
};

export default VideoDisplay;
